import { defineStore } from 'pinia'
import {  logFeatureFlagImpression  } from '@/services/utility/braze.utility'
import type { FeatureFlag } from '@braze/web-sdk'

export const useFeatureFlagsStore = defineStore('feature-flags', {
  state: () => ({
    featureFlags: []  as readonly FeatureFlag[],
    pizzasPageCategory:[
      'meatpizzas',
      'chickenpizzas',
      'prawnpizzas',
      'veggiepizzas'
    ]
  }),

  getters: {
    getFeatureFlag: (state) => (id: string) => {
      logFeatureFlagImpression(id)
      return state.featureFlags.find((flag: FeatureFlag) => flag.id === id)
    }
  },

  actions: {
    setFeatureFlags (flags: FeatureFlag[]) {
      this.featureFlags = Object.freeze(flags)
    }
  }
})
